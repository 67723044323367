




















import SfRadio from '/storefrontUI/components/components/molecules/SfRadio/SfRadio.vue';
import { ref, onMounted, defineComponent } from '@nuxtjs/composition-api';
import usePaymentProvider from '~/modules/checkout/composables/usePaymentProvider';
import type { AvailablePaymentMethod } from '~/modules/GraphQL/types';

export default defineComponent({
  name: 'VsfPaymentProvider',
  components: {
    SfRadio,
  },
  emits: ['status'],
  setup(_props, { emit }) {
    const paymentMethods = ref<AvailablePaymentMethod[]>([]);
    const { load, save } = usePaymentProvider();
    const selectedPaymentMethodCode = ref<string | null>(null);

    onMounted(async () => {
      paymentMethods.value = await load();
    });

    const definePaymentMethods = async (paymentMethodCode: string) => {
      paymentMethods.value = await save({
        paymentMethod: {
          code: paymentMethodCode,
        },
      });

      selectedPaymentMethodCode.value = paymentMethodCode;

      emit('status', paymentMethodCode);
    };

    return {
      paymentMethods,
      selectedPaymentMethodCode,
      definePaymentMethods,
    };
  },
});
